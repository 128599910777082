<template>
  <div class="header">
    <img class="bg" src="../assets/image/headerbg.png" />
    <!-- 丰景不要显示一切大旭元素 -->
    <router-link class="logo" to="/index" custom>
      <img src="../assets/image/logo.png" role="link" v-if="farmID !== 59" />
    </router-link>
    <div class="title">
      <span>{{
        farmInfo.name ? farmInfo.name : "大旭智能数字农场管理平台"
      }}</span>
    </div>
    <div class="menu">
      <div @click="changeFarm" class="cursor">
        <img src="../assets/image/header-logo-1.png" />
        <span>{{ farmName }}</span>
        <img src="../assets/image/bottom.png" />
      </div>
      <el-popover class="user-popover" :popperClass="popClass">
        <div class="user-div" slot="reference">
          <span>{{ userInfo.uid }}</span>
          <img src="../assets/image/bottom.png" />
        </div>
        <div class="user-ul">
          <p class="user-p" @click="goNav('datum')">个人资料</p>
          <p class="user-p" @click="goNav('password')">账号设置</p>
          <p class="user-p" @click="goNav('farmAdmin')">农场管理</p>
          <p class="user-p" @click="goNav('farmIndex')">农场秀</p>
          <p class="user-p out-login" @click="outLogin">退出登录</p>
        </div>
      </el-popover>
      <div>
        <img
          @click="goBigWeb"
          class="daping-img"
          src="../assets/image/dapng.png"
          alt=""
          title="大屏界面"
        />
        <img
          @click="intoFullScreen"
          class="daping-img"
          src="../assets/image/full.png"
          alt=""
          :title="fullTitle"
        />
        <el-badge :value="msgCount" :max="99" :hidden="!msgCount">
          <p @click="changeMag" class="cursor" ref="cursor">
            <img src="../assets/image/massage.png" title="通知" />
          </p>
        </el-badge>

        <p class="popup-msg">
          <el-popover v-model="visibleMsg" width="260" :popperClass="popClass">
            <div class="popup-msg-box">
              <span class="title">未读通知</span>
              <div
                class="info-div"
                v-for="(item, index) in msgList"
                :key="index"
              >
                <span class="msg-title">{{ item.title }}</span>
                <br />
                <span class>{{ item.created_time }}</span>
                <span class="color-3E90E5 fr">{{ item.sname }}</span>
                <br />
                <span class="content-msg">{{ item.desc }}</span>
                <router-link :to="'/news?id=' + item.id">详情&gt;</router-link>
              </div>
              <MyNothing v-if="!msgList.length"></MyNothing>
              <p class="go-news" @click="goNews">查看所有消息&gt;</p>
            </div>
          </el-popover>
        </p>
        <!-- 霍邱72没有任务计划 -->
        <el-badge
          :value="taskCount"
          :max="99"
          :hidden="isHidden"
          v-if="farmID !== 72"
        >
          <p class="cursor" @click="goTaskPage">
            <img src="../assets/image/schedule.png" title="任务计划" />
          </p>
        </el-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getTaskCountApi } from "@/request/api.js";
import screenfull from "screenfull";

export default {
  components: {
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  props: {
    farmName: {
      type: String,
    },
    msgList: {
      type: Array,
    },
  },
  data() {
    return {
      visibleMsg: false,
      popClass: "header-popover",
      fullTitle: "进入全屏",
    };
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      msgCount: (state) => state.user.msgCount,
      taskCount: (state) => state.user.taskCount,
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
    }),
    isHidden() {
      let count = this.taskCount <= 0;
      return count;
    },
  },
  created() {
    this.getTaskCountApi();
  },

  beforeDestroy() {
    this.destroy();
  },
  methods: {
    ...mapMutations("user", ["SET_TASK_COUNT", "SET_TOKEN", "SET_FARM_Id"]),
    change() {
      this.fullTitle = screenfull.isFullscreen ? "退出全屏" : "进入全屏";
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off("change", this.change);
      }
    },
    intoFullScreen() {
      if (!screenfull.isEnabled) {
        this.$notify({
          title: "提示",
          message: "不支持全屏",
          type: "info",
        });
        return;
      }
      screenfull.toggle();
      screenfull.on("change", this.change);
    },
    goBigWeb() {
      // 荆山8
      if (this.farmID == 8) {
        this.$router.push({
          name: "jsweb",
        });
        return;
      }
      // 丰景59
      if (this.farmID == 59) {
        this.$router.push({
          name: "jfWeb",
        });
        return;
      }
      // 庐江洋苗63
      if (this.farmID == 63) {
        this.$router.push({
          name: "ljymWeb",
        });
        return;
      }
      if (this.farmInfo.screen_page) {
        window.open(this.farmInfo.screen_page);
      } else {
        this.$router.push({
          name: "bigWeb",
        });
      }
    },
    changeMag() {
      this.visibleMsg = !this.visibleMsg;
    },
    async getTaskCountApi() {
      const { code, results } = await getTaskCountApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        let taskCount = results.data.find((item) => {
          return item.state === 1;
        }).count;
        this.SET_TASK_COUNT({ taskCount });
      }
    },
    goNav(str) {
      this.$router.push({
        name: str,
      });
    },

    outLogin() {
      const lihai = localStorage.getItem("farmId");

      this.$confirm("确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.removeItem("web_menu");
          localStorage.removeItem("userInfoObj");
          localStorage.removeItem("farmId");
          localStorage.removeItem("farmInfo");
          localStorage.removeItem("darsingToken");
          sessionStorage.clear();
          this.SET_TOKEN({ token: {} });
          this.SET_FARM_Id({ farmId: "" });
          this.$notify({
            title: "成功",
            message: "已退出登录",
            type: "success",
          });
          if (lihai == 59) {
            this.$router.push({
              name: "fj",
            });
          } else {
            this.$router.push({
              name: "login",
            });
          }
        })
        .catch(() => {});
    },
    changeFarm() {
      this.$emit("changeFarm");
    },
    goNews() {
      this.visibleMsg = false;
      this.$router.push({
        path: "/news",
      });
    },
    goTaskPage() {
      this.visibleMsg = false;
      this.$router.push({
        path: "/task",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 62px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  background-color: #0f243d;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0px;
  z-index: 2000;
  .logo {
    width: 178px;
    height: 33px;
    cursor: pointer;
  }
  img.bg {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: 0;
  }
  > div {
    width: 26%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  > div.title {
    justify-content: center;
    font-size: 26px;
    color: rgba(62, 144, 229, 1);
    font-weight: bold;
    background: linear-gradient(0deg, #3e90e5 0%, #3eb8e5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    flex: 1;
    padding: 0 0 0 293px;
    // position: relative;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      position: relative;
      img {
        margin: 0 10px;
        vertical-align: middle;
      }
      > p {
        position: relative;
        margin-left: 6px;
      }
    }

    p.popup-msg {
      left: -120px;
      /deep/.el-popover.el-popper.header-popover {
        top: 33px !important;
        padding: 0 !important;
      }
      .popup-msg-box {
        z-index: 2001;
        .go-news {
          width: 100%;
          text-align: center;
          padding: 10px 20px;
          cursor: pointer;
          &:hover {
            color: #3e90e5;
            text-decoration: underline;
          }
        }
        .title {
          display: inline-block;
          width: 100%;
          padding: 8px 0;
          text-align: center;
          font-size: 16px;
        }
        > a {
          width: 100%;
          text-align: center;
          padding: 10px 0;
          color: #fff;
        }
        > div {
          padding: 10px 20px;
          border-top: 1px solid rgba(255, 255, 255, 0.21);
          .msg-title {
            font-size: 14px;
          }

          .content-msg {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          a {
            width: 100%;
            text-align: right;
            padding: 0;
            font-size: 12px;
            &:hover {
              color: #3e90e5;
              text-decoration: underline;
            }
          }
          > span {
            display: inline-block;
            line-height: 18px;
            margin-top: 8px;
            font-size: 12px;
          }
        }
      }
    }

    p.popup-msg {
      padding: 20px 0;
      position: absolute;
      top: 10px;
      a {
        color: #fff;
        padding: 8px 0;
        display: inline-block;
      }
    }
    .cursor {
      cursor: pointer;
    }
  }
}
&/deep/ .el-popover {
  width: 260px !important;
  background: rgba(15, 36, 61, 0.92);
  border: none;
  color: #fff;
  padding: 0;
  box-sizing: border-box;
}
&/deep/ .el-checkbox {
  margin: 5px 0 5px 0;
}
&/deep/ .el-checkbox__inner {
  background: rgba(15, 36, 61, 0.88);
  border: 1px solid rgba(62, 144, 229, 1);
}
/deep/.el-badge__content.is-fixed {
  right: 19px;
}
.daping-img {
  width: 28px;
  cursor: pointer;
}
</style>
